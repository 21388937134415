import { Entity } from '@/settings/domain/entity/entity/Entity';
import { Seal } from '../preRegister/Seal';
import Airport from '@/settings/domain/airport/Airport';

export class BillOfLanding {
  serial: string;
  sealList: Seal[] = [];
  sender: Entity | null;
  recipient: Entity | null;
  transportistName: string;
  transportistId: string;
  departureAirport: Airport | null;
  destinationAirport: Airport | null;
  deliveryName: Entity | null;
  licensePlate: string;
  notes: string;
  createdAt: string;
  updatedAt: string;
  active: boolean;
  date: string;
  senderFOB: boolean;
  senderZipCode: string;
  masterBillOfLanding: string;
  houseBillOfLandingList: BillOfLanding[];
  isNew: boolean; //Esta propiedad se usa en el formulario de creación de BL para saber si es un nuevo registro o no, es decir, si viene de la BD o no y así poder limitar la edición de ciertos campos
  newShipper: string;
  newConsignee: string;

  public constructor() {
    this.serial = '';
    this.sealList = [];
    this.sender = null;
    this.recipient = null;
    this.transportistName = '';
    this.transportistId = '';
    this.departureAirport = null;
    this.destinationAirport = null;
    this.deliveryName = null;
    this.licensePlate = '';
    this.notes = '';
    this.createdAt = '';
    this.updatedAt = '';
    this.active = true;
    this.date = '';
    this.senderFOB = false;
    this.senderZipCode = '';
    this.masterBillOfLanding = '';
    this.houseBillOfLandingList = [];
    this.isNew = true;
    this.newShipper = '';
    this.newConsignee = '';
  }
}
